import React, { useEffect, useState } from 'react'

const GreenhouseBoard = () => {
  const [ghJid, setGhJid] = useState('')

  useEffect(() => {
    if (typeof window !== 'undefined') {
      const params = new URLSearchParams(window.location.search)
      const jid = params.get('gh_jid')
      setGhJid(jid ? `&token=${jid}` : '')

      const resizeScript = document.createElement('script')
      resizeScript.innerHTML = `
        window.addEventListener("message", receiveMessage, false);
        function receiveMessage(event) {
          const { origin, data } = event;
          if (origin !== "https://boards.greenhouse.io") return;

          const height = parseInt(data, 10);
          if (!isNaN(height)) {
            const element = document.getElementById("grnhse_app");
            if (element && element.firstElementChild) {
              element.firstElementChild.style.height = height + "px";
            }
          }
        }`
      document.body.appendChild(resizeScript)

      return () => {
        document.body.removeChild(resizeScript)
      }
    }
    return () => {}
  }, [])

  const baseIframeSrc = `https://boards.greenhouse.io/embed/${
    ghJid ? 'job_app' : 'job_board'
  }?for=clearlinktechnologiesllc`
  const iframeSrc = `${baseIframeSrc}${ghJid}`

  return (
    <div id="grnhse_app">
      <iframe src={iframeSrc} id="grnhse_iframe" title="Greenhouse Job Board" />
    </div>
  )
}

export default GreenhouseBoard
