/* eslint-disable */
import React from 'react'
import { StaticImage } from 'gatsby-plugin-image'
import NarrowBanner from '../components/NarrowBanner'

import PurposeDriven from '../images/people/purpose-driven-black-and-white.png'
import WorkLife from '../images/people/work-life-balance-black-and-white.png'
import HealthWellness from '../images/people/health-wellness-black-and-white.png'
import Match401K from '../images/people/401k-match-black-and-white.png'
import TripOfALifetime from '../images/people/trip-of-a-lifetime-black-and-white.png'
import LegacyAwards from '../images/people/legacy-awards-black-and-white.png'
import Headquarters from '../images/headquarters-small.webp'
import HeadquartersDesktop from '../images/headquarters-large.webp'

import {
  Columns,
  Column,
  LinkButton,
  VariableContent,
  Typography,
  Stack,
  SplitContent,
  List,
  ListItem,
} from '@leshen/gatsby-theme-leshen'
import { Layout } from '@leshen/gatsby-theme-contentful'

import header from '../components/Header'
import footer from '../components/Footer'
import Footer from '../components/Footer'
import footerData from '../data/footerData'
import headerData from '../data/headerData'
import GreenhouseBoard from '../components/GreenHouseBoard'

const Home = () => {
  const layoutProps = {
    data: {
      contentfulPage: {
        header: header(),
        footer: footer(),
        header: header(),
        footer: footer(),
        seo: {
          title: 'Careers | Clearlink',
          description:
            'We are passionate about our culture, our craft, and especially our people. Bring your experience, further develop your skills, and grow your career with us.',
          canonical: 'https://clearlink.com/careers',
          robots: 'follow,index',
        },
        path: '/careers',
        promoCode: 'SAMPLEPROMO',
        ringPool: 'RINGPOOL123',
        hideStickyCTA: false,
        spanish: false,
        sections: [
          {
            // Add any relevant section data
          },
        ],
      },
      site: {
        siteMetadata: {
          siteURL: 'https://clearlink.com',
          siteName: 'Clearlink',
          alternateName: 'Clearlink',
          removeTrailingSlashes: true,
        },
      },
    },
    titleTemplate: 'Careers | Clearlink',
    defaultRobots: 'follow,index',
    main: (
      <>
        <VariableContent backgroundColor="dark" alignMainContent="left">
          <div
            data-sal="slide-right"
            data-sal-delay="100"
            data-sal-duration="500"
            data-sal-easing="ease"
          >
            <Typography variant="h1">
              Careers<span className="blue-punctuation-large">.</span>
            </Typography>
            <Typography variant="h4" style={{ marginBottom: '26px' }}>
              Make a lasting impact doing what you love
            </Typography>
          </div>
        </VariableContent>
        <GreenhouseBoard />
        <VariableContent backgroundColor="primary" alignMainContent="left">
          <div
            data-sal="slide-up"
            data-sal-delay="100"
            data-sal-duration="500"
            data-sal-easing="ease"
          >
            <Stack spacing="l">
              <Typography
                variant="h2"
                style={{ margin: '0 0 50px', textAlign: 'left' }}
              >
                Employee Perks<span className="white-punctuation-large">.</span>
              </Typography>
              <Typography style={{ margin: '0 auto 16px', textAlign: 'left' }}>
                Clearlink is composed of two branches of business. Clearlink
                Enterprise Services includes performance-based marketing and
                sales solutions. Clearlink Digital Media encompasses our
                in-house brands and digital media publications.
              </Typography>
              <Typography style={{ margin: '0 auto 32px', textAlign: 'left' }}>
                We believe a company can offer its employees much more than a
                paycheck, which is why our benefits package is so robust. Here
                are just some highlights of the perks that come with a position
                at Clearlink.
              </Typography>
            </Stack>
          </div>
          <div
            data-sal="slide-up"
            data-sal-delay="100"
            data-sal-duration="500"
            data-sal-easing="ease"
          >
            <Stack spacing="l">
              <Columns mobileCarousel>
                <Column className="leshen-column-warpper">
                  <img
                    src={PurposeDriven}
                    alt="Purpose-Driven Work"
                    style={{ margin: '0 0 24px' }}
                  />
                  <Typography
                    variant="h6"
                    style={{ maxWidth: '372px', textAlign: 'left' }}
                  >
                    Purpose-Driven Work
                  </Typography>
                  <Typography style={{ maxWidth: '372px', textAlign: 'left' }}>
                    Everything we do is driven by our purpose: to simplify
                    life’s decisions for everyone. This is evident in every
                    meeting, project, and interaction at Clearlink.
                  </Typography>
                </Column>
                <Column className="leshen-column-warpper">
                  <img
                    src={WorkLife}
                    alt="Work/Life Balance"
                    style={{ margin: '0 0 24px' }}
                  />
                  <Typography
                    variant="h6"
                    style={{ maxWidth: '372px', textAlign: 'left' }}
                  >
                    Work/Life Balance
                  </Typography>
                  <Typography style={{ maxWidth: '372px', textAlign: 'left' }}>
                    In addition to observing national holidays, we provide a
                    generous paid-time-off policy which reflects our belief that
                    we should work to live, not the other way around.
                  </Typography>
                </Column>
                <Column className="leshen-column-warpper">
                  <img
                    src={HealthWellness}
                    alt="Health & Wellness Benefits"
                    style={{ margin: '0 0 24px' }}
                  />
                  <Typography
                    variant="h6"
                    style={{ maxWidth: '372px', textAlign: 'left' }}
                  >
                    Health &amp; Wellness Benefits
                  </Typography>
                  <Typography style={{ maxWidth: '372px', textAlign: 'left' }}>
                    We offer industry-leading health insurance options for you
                    and your family, with access to premier healthcare network
                    providers to help you live the best life possible. On top of
                    that, we also offer a completely employer-funded Lifestyle
                    Spending Account, Paid Parental Leave as well as an enhanced
                    Mental Health platform.
                  </Typography>
                </Column>
                <Column className="leshen-column-warpper">
                  <img
                    src={Match401K}
                    alt="401K Match"
                    style={{ margin: '0 0 24px' }}
                  />
                  <Typography
                    variant="h6"
                    style={{ maxWidth: '372px', textAlign: 'left' }}
                  >
                    401(K) Match
                  </Typography>
                  <Typography style={{ maxWidth: '372px', textAlign: 'left' }}>
                    We realize you’re not just working for today, so Clearlink
                    matches a portion of your 401(k) contributions, making sure
                    your financial future is as bright as your present.
                  </Typography>
                </Column>
                <Column className="leshen-column-warpper">
                  <img
                    src={TripOfALifetime}
                    alt="Trip of a Lifetime"
                    style={{ margin: '0 0 24px' }}
                  />
                  <Typography
                    variant="h6"
                    style={{ maxWidth: '372px', textAlign: 'left' }}
                  >
                    Trip of a Lifetime
                  </Typography>
                  <Typography style={{ maxWidth: '372px', textAlign: 'left' }}>
                    Every year, we reward our top employees with a dream trip.
                    They pick the destination, activities, and their travel
                    companion, and we take care of everything else.
                  </Typography>
                </Column>
                <Column className="leshen-column-warpper">
                  <img
                    src={LegacyAwards}
                    alt="Legacy Awards"
                    style={{ margin: '0 0 24px' }}
                  />
                  <Typography
                    variant="h6"
                    style={{ maxWidth: '372px', textAlign: 'left' }}
                  >
                    Legacy Awards
                  </Typography>
                  <Typography style={{ maxWidth: '372px', textAlign: 'left' }}>
                    We celebrate many tenure milestones with gifts, cash, and
                    additional PTO. A couple noteworthy anniversaries: two
                    weeks’ paid time off at 5 years and one-month paid time off
                    at 10 years.
                  </Typography>
                </Column>
              </Columns>
            </Stack>
          </div>
        </VariableContent>
        <SplitContent
          id="hq"
          image={
            <img
              src={HeadquartersDesktop}
              alt="Clearlinks global headquarters in the heart of Silicon Slopes"
            />
          }
          mobileImage={
            <img
              src={Headquarters}
              alt="Clearlinks global headquarters in the heart of Silicon Slopes"
            />
          }
          backgroundColor="gray"
        >
          <div
            data-sal="slide-left"
            data-sal-delay="300"
            data-sal-duration="500"
            data-sal-easing="ease"
          >
            <Typography variant="h2">
              Only the best for our employees
              <span className="blue-punctuation-large">.</span>
            </Typography>
            <Typography variant="body">
              Nestled in the heart of Silicon Slopes overlooking the valley
              below lies our world-class global headquarters. With convenient
              access to both Salt Lake and Utah Valleys, it is the perfect
              gathering place for all our talented employees in the Beehive
              State. 
            </Typography>
            <Typography variant="body">
              Our headquarters are the heartbeat of everything we do—providing a
              collaborative space for our in-office workers while offering the
              resources and technology to seamlessly integrate our remote team
              members. Our state-of-the-art building is designed around
              accessibility and autonomy with all the amenities an employee
              needs to be their very best including:
            </Typography>
            <List type="unordered">
              <ListItem>
                <strong>
                  7,000 sq ft gym facilities with locker rooms and yoga studio
                </strong>
              </ListItem>
              <ListItem>
                <strong>
                  On-site restaurant options featuring delicious food at
                  subsidized prices
                </strong>
              </ListItem>
              <ListItem>
                <strong>
                  Pickleball courts, a golf simulator, pool tables, ping pong,
                  etc.
                </strong>
              </ListItem>
              <ListItem>
                <strong>
                  Modern open office layout, with state-of-the-art meeting rooms
                </strong>
              </ListItem>
              <ListItem>
                <strong>
                  Shuttle service to nearby Frontrunner train station
                </strong>
              </ListItem>
              <ListItem>
                <strong>
                  Bike check-out available for trails and roads nearby
                </strong>
              </ListItem>
              <ListItem>
                <strong>
                  Free drinks and snacks that are restocked regularly
                </strong>
              </ListItem>
            </List>
          </div>
        </SplitContent>
        <NarrowBanner
          backgroundColor="#000000"
          heading={
            <div
              data-sal="slide-right"
              data-sal-delay="300"
              data-sal-duration="500"
              data-sal-easing="ease"
            >
              <Typography variant="h5" className="s2">
                Questions?
              </Typography>
            </div>
          }
          button={
            <LinkButton
              to="/contact"
              color="primary"
              data-sal="slide-left"
              data-sal-delay="300"
              data-sal-duration="500"
              data-sal-easing="ease"
            >
              Contact Us
            </LinkButton>
          }
        />
      </>
    ),
    disableBreadcrumbs: false,
  }

  return <Layout {...layoutProps} />
}
export default Home
